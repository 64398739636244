.App {
  text-align: center;
}

.z-index-top-1 {
  position: absolute;
  z-index: 2;

  left: 84%;
  top: -5px;
}

.z-index-top-2 {
  position: absolute;
  z-index: 2;

  left: 77%;
  top: -5px;
}

.p-1 {
  padding: 0.25rem !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.div-full-width {
  width: 100% !important;
}

.table-header {
  height: 35px;
  background-color: #0000001e;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}

.infoTable tbody tr td:first-of-type {
  width: 50%;
}

.infoTable tbody tr td:last-of-type {
  width: 50%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-no-style {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
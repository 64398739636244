.btn-primary {
  background: rgb(108, 179, 63);
  border-color: rgb(108, 179, 63);
}

.fixed-button {
  max-width: 60px !important;
  min-width: 60px !important;
  margin-top: 6px !important;
}

.btn-focus:focus {
  background: rgb(0, 43, 92) !important;
}

.btn-default {
  background: rgb(0, 43, 92) !important;
  color: white !important;
}

.btn-color-change {
  background: #6c757d !important;
  border-color: #f8f9fa !important;
  color: white !important;
}

.btn-color-change:focus {
  background: rgb(0, 43, 92);
  color: white;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open>.dropdown-toggle.btn-primary {
  background: white;
  border-color: rgb(108, 179, 63);
  color: rgb(108, 179, 63);
}

.pagination>li>a {
  background-color: white;
  color: rgb(108, 179, 63);
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
  color: #5a5a5a;
  background-color: #eee;
  border-color: #ddd;
}

.pagination>.active>a {
  color: white;
  background-color: rgb(108, 179, 63) !important;
  border: solid 1px rgb(108, 179, 63) !important;
}

.pagination>.active>a:hover {
  background-color: rgb(108, 179, 63) !important;
  border: solid 1px rgb(108, 179, 63);
}

.form-control[disabled] {
  background-color: darkgray;
}

.results-td {
  width: 60%;
}

.edit-col {
  width: 20%;
}

.admin-comp {
  width: 15%;
}

.navbar-light .navbar-nav .nav-link {
  color: rgb(0, 43, 92);
  border-color: rgb(108, 179, 63) !important;
  background-color: transparent !important;

}

.dark-blue {
  color: rgb(0, 43, 92);
}

.contour-blue {
  background-color: rgb(0, 43, 92)
}

.fixed-state-button {
  max-height: 40px;
}

.modal-wrapper {
  outline: "none";
  background: white !important;
  border: 3px solid white !important;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
  margin: 100px auto 0;
  transition: all 0.8s;
  width: 40%;
  height: 30%;
}

.center-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-header {
  height: 40px;
  line-height: 40px;
  padding: 5px 20px;
  text-align: right;
}

.modal-header h3 {
  color: rgb(0, 43, 92) !important;
  float: left;
  margin: 0;
  padding: 0;
}

.modal-body {
  text-align: center;
}

.modal-footer {
  align-items: center;
  justify-content: center;
}

.textarea-control {
  height: 325px !important;
}

.textarea-control2 {
  height: 160px !important;
}

.modal-filter-selector {

  width: 900px !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);


}

.filter-filler {
  height: 200px;
}

.modal-attribute-selector {

  width: 1200px !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);


}

/* .modal-dialog {
  max-width: none !important;
} */
.modal-filter-selector-state {
  height: 400px;


}


.trashcan:hover {
  background-color: red;
}

.list-group-item-dark {
  background-color: rgb(108, 179, 63);
  color: white;
}

.list-group-item {
  padding: 5px 10px;
}

.list-group-item-success {
  background-color: rgb(114, 158, 86, 0.3);
  color: black;
}

.icon-pic {
  height: 20px;
}

.report-card {
  height: 25px;
}

.report-card-button {
  position: absolute;
  bottom: 0;
}

.card-hover:hover {

  background-color: rgb(0, 43, 92);
  color: white;


}

.selected-card {
  background-color: rgb(108, 179, 63);
  color: white;
}

/* .close {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0%;
    padding: 12px 16px;
    transform: translate(0%, -50%);
  
  } */
.pagination a {
  color: black !important;
  float: left !important;
  padding: 8px 16px !important;
  text-decoration: none !important;
  transition: background-color 0.3s !important;
}

.pagination a.active {
  background-color: rgb(0, 43, 92) !important;
  color: white !important;
}

.pagination li:hover:not(.active) {
  background-color: #ddd !important;
}

.search-row:hover {
  background-color: rgb(108, 179, 63, 0.3) !important;
}

.input-small {
  height: 25px !important;
  padding: 0px !important;
}


.lead-queue-count {
  z-index: 5;
  position: relative;
  float: right;
  left: 8px;
  top: 1px;

}

.z-index-5 {
  position: absolute;
  z-index: 5;
  left: 80%;
  top: 20%;
}

.script-width {
  width: 300px !important
}

input[type="text"][disabled] {
  background-color: #D3D3D3
}

.sortHeaderCell {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.sortHeaderCell:hover {
  background-color: rgb(0, 0, 0, 0.25);
}

.sortHeaderCell:active {
  background-color: rgb(0, 0, 0, 0.33);
}

.sortButton {
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 5px;
}

.sortButton:focus {
  outline: 0;
}

.sortButton i {
  font-size: 20px
}

.background-color-green {
    background-color: rgb(108, 179, 63);
}